const languages = require('./language');

module.exports = [
  {
    name: 'Hello World',
    fileName: 'hello-world',
  },


  {
    name: 'Factorial',
    fileName: 'factorial',
  },

  {
    name: 'Binary Search',
    fileName: 'binary-search',
  },
];
