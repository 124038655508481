import './styles.scss';

export default function () {
  return {
    view: () => {
      return (
        <footer id="site-foot" className="uk-section uk-section-secondary uk-section-xsmall">
        </footer>
      );
    },
  };
}